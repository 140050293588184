import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
const greenColor = "uiGreen";
const redColor = "uiRed";
const statuses = {
  available: {
    label: "Available",
    color: greenColor
  },
  running: {
    label: "Available (Running)",
    color: greenColor
  },
  calibrating: {
    label: "Available (Calibrating)",
    color: greenColor
  },
  reserved: {
    label: "Available (Reserved)",
    color: greenColor
  },
  unavailable: {
    label: "Unavailable",
    color: redColor
  },
  maintenance: {
    label: "Unavailable (Maintenance)",
    color: redColor
  },
  retired: {
    label: "Unavailable (Retired)",
    color: redColor
  },
  offline: {
    label: "Unavailable (Offline)",
    color: redColor
  }
};
function SystemStatus(props) {
  const status = props.status || "unavailable";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Icon,
    {
      as: MdCircle,
      boxSize: "9px",
      mb: "1px",
      color: statuses[status].color
    }
  ), /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      ml: 1,
      textStyle: "body-8",
      color: "gray.600",
      className: "system-status-text"
    },
    statuses[status].label
  ));
}
SystemStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(statuses))
};
export default SystemStatus;
