import React from "react";
import Profile from "./AccountSettings/Profile";
import ProfileImage from "./AccountSettings/ProfileImage";
import Password from "./AccountSettings/Password";
import Multifactor from "./AccountSettings/Multifactor";
import Allowlist from "./AccountSettings/Allowlist";
import { TimeoutCheckbox } from "./AccountSettings/TimeoutCheckbox";
import { useMe } from "../shared/Me/useMe.js";
import { Center, Spinner, Text } from "@chakra-ui/react";
import api from "../../api.js";
const AccountSettings = () => {
  const { isLoading, data, refetch } = useMe();
  const { firstName, lastName, email, timeZone, mfa } = data || {};
  const [qrcode, setQrcode] = React.useState(null);
  const isSSO = email && !email.split("@").shift().includes("+") && /.ionq.co(m)?$/.test(email);
  return isLoading ? /* @__PURE__ */ React.createElement(Center, { p: "40px" }, /* @__PURE__ */ React.createElement(Spinner, null)) : /* @__PURE__ */ React.createElement("div", { className: "AccountSettings" }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Account Settings"), /* @__PURE__ */ React.createElement(
    Profile,
    {
      onSubmit: (res) => api.updateUser(res.values).then(refetch),
      user: { firstName, lastName, timeZone }
    }
  ), /* @__PURE__ */ React.createElement(ProfileImage, { email }), /* @__PURE__ */ React.createElement(
    Password,
    {
      isSSO,
      onSubmit: (res) => api.updatePassword(res.values).then(refetch)
    }
  ), /* @__PURE__ */ React.createElement(TimeoutCheckbox, null), /* @__PURE__ */ React.createElement(
    Multifactor,
    {
      isSSO,
      enabled: mfa?.enabled,
      qrcode,
      onEnroll: () => api.enrollAuthFactor().then((res) => {
        setQrcode(res.qrcode);
      }, console.error),
      onActivate: (res) => api.activateAuthFactor(res.values.passCode).then(refetch),
      onCancel: refetch
    }
  ), /* @__PURE__ */ React.createElement("h3", { className: "settings-page-headline" }, "IP allow list"), /* @__PURE__ */ React.createElement(
    Allowlist,
    {
      list: data.ipAllowlist,
      onUpdateUserIpWhitelist: () => {
      }
    }
  ));
};
export default AccountSettings;
